<template>
	<b-card>
		<div class="custom-search">
			<b-form-group>
				<div class="d-flex align-items-center">
					<b-form-input
						v-model="searchQuery"
						placeholder="Search"
						type="text"
						class="d-inline-block"
					/>
				</div>
			</b-form-group>
		</div>
		<b-table
			ref="refCustomersListTable"
			bordered
			hover
			responsive
			class="shadow-sm rounded"
			thead-tr-class="order-main-thead"
			:items="customersList"
			:busy="isTableBusy"
			:fields="fields"
			primary-key="id"
			show-empty
			empty-text="No matching records found"
			small
			@row-clicked="onRowClicked"
		>
			<template #cell(actions)="data">
				<b-button
					variant="flat-primary"
					class="btn-icon"
					@click="editCustomerButton(data.item)"
				>
					<feather-icon size="16" icon="EditIcon" />
				</b-button>
				<b-button
					class="btn-icon"
					variant="flat-danger"
					@click="removeCustomerButton(data.item)"
				>
					<feather-icon size="16" icon="TrashIcon" />
				</b-button>
			</template>
		</b-table>
		<div v-if="false" class="mx-2 mb-2">
			<b-row>
				<b-col
					cols="12"
					sm="6"
					class="d-flex align-items-center justify-content-center justify-content-sm-start"
				>
					<span v-if="true" class="text-muted">
						Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
						{{ dataMeta.of }} entries
					</span>
				</b-col>
				Pagination
				<b-col
					cols="12"
					sm="6"
					class="d-flex align-items-center justify-content-center justify-content-sm-end pr-sm-4"
				>
					<b-pagination
						v-model="currentPage"
						:total-rows="totalProducts"
						:per-page="perPage"
						first-number
						last-number
						class="mt-1 mb-0 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
		</div>
	</b-card>
</template>

<script>
import {
	BTable,
	BCard,
	BFormInput,
	BFormGroup,
	BButton,
	BCol,
	BPagination,
} from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';

export default {
	components: {
		BTable,
		BCard,
		BButton,
		BFormInput,
		BFormGroup,
		BCol,
		BPagination,
	},
	data() {
		return {
			customersList: [],
			searchQuery: '',
			fields: [
				{
					label: 'id',
					key: 'id',
				},
				{
					label: 'Name',
					key: 'firstName',
					sortable: true,
					formatter: (value, key, item) => `${item.firstName} ${item.lastName}`,
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
				},
				{
					label: 'Date of Birth',
					key: 'dob',
					sortable: false,
					formatter: (value) => value.split(' ')[0],
				},
				{
					key: 'createdAt',
					label: 'Registered At',
					sortable: true,
					formatter: (value) => value.split(' ')[0],
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
				},
				{
					key: 'mobile',
					label: 'Mobile',
					sortable: false,
				},
				{
					key: 'gender',
					label: 'Gender',
				},
				{
					label: 'actions',
					key: 'actions',
				},
			],
		};
	},

	computed: {
		...mapState('customersModule', ['customers', 'isTableBusy']),
	},
	watch: {
		searchQuery(newVal) {
			this.getCustomers({
				skip: 0,
				take: 10,
				searchKey: newVal,
			});
		},
		customers(newVal) {
			this.customersList = newVal;
			console.log('customersList', newVal);
		},
	},
	created() {
		this.getCustomers({
			skip: 0,
			take: 10,
			searchKey: '',
		});
	},
	methods: {
		...mapActions('customersModule', ['getCustomers']),
		editCustomerButton(prod) {
			console.log(prod);
		},
		removeCustomerButton(prod) {
			console.log(prod);
		},
		onRowClicked(item) {
			console.log(item);
		},
	},
};
</script>

<style lang="scss" scoped>
.custom-search {
	display: flex;
	justify-content: flex-end;
	@media (max-width: 600px) {
		width: 100%;
		justify-content: flex-start;
	}
}
.form-group {
	@media (max-width: 600px) {
		width: 100%;
	}
}
.pointer {
	cursor: pointer;
}
</style>
